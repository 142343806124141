<template>
<el-row class="t-container">
  <div ref="container" class="t-container"></div>
  <el-col class="t-login" :span="24">
    <el-col class="t-form" :span="24">
      <span class="t-water"></span>
      <el-col class="t-title" :span="24">{{ title }}</el-col>
      <el-col class="t-box" :span="24">
        <el-input v-model="submitInfo.account" placeholder="请输入账号"></el-input>
      </el-col>
      <el-col class="t-box" :span="24">
        <el-input v-model="submitInfo.password" type="password" placeholder="请输入密码"></el-input>
      </el-col>
      <el-col class="t-submit" :span="24">
        <span>登录</span>
      </el-col>
    </el-col>
    <span class="t-forget">忘记密码</span>
    <span class="t-register">注册</span>
  </el-col>
</el-row>
</template>


<script >
import * as THREE from 'three';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Stats from 'three/examples/jsm/libs/stats.module';
export default {
  name: `login`,
  data(){
    return{
      container: null,// 容器
      width: 0,// 声明视口宽度
      height: 0,// 声明视口高度
      depth: 1400,// 盒模型的深度
      scene: null,// 声明场景
      Sphere_Group: null,// 声明球组
      sphereGeometry: null,// 声明球体几何
      sphere: null, // 声明完整球
      camera: null,// 声明相机
      zAxisNumber: 0, // 声明相机在z轴的位置
      cameraTarget: new THREE.Vector3(0,0,0), // 声明相机目标点
      materials: [], // 声明点材质
      parameters: null, // 声明点的参数
      zprogress: 0,
      zprogress_second: 0,
      particles_first: [],
      particles_second: [],
      particles_init_position: 0,
      cloudParameter_first: null,
      cloudParameter_second: null,
      renderCloudMove_first: null,
      renderCloudMove_second: null,
      stats: new Stats(),
      renderer: null,
      gui: new GUI(),
      title: process.env.VUE_APP_TITLE,
      submitInfo:{
        account: '',
        password: '',
      }
    }
  },
  mounted () {
    this.$nextTick(() => this.nextTick());
  },
  methods:{
    nextTick(){
      this.container = this.$refs.container;
      this.width = this.container.clientWidth;
      this.height = this.container.clientHeight;
      this.initScene()
      this.initSceneBg()
      this.initCamera()
      this.initLight()
      this.initSphereModal()
      this.initSphereGroup()
      this.particles_init_position = -this.zAxisNumber - this.depth / 2
      this.zprogress = this.particles_init_position
      this.zprogress_second = this.particles_init_position * 2
      this.particles_first = this.initSceneStar(this.particles_init_position)
      this.particles_second = this.initSceneStar(this.zprogress_second)
      this.cloudParameter_first = this.initTubeRoute(
          [
            new THREE.Vector3(-this.width / 10, 0, -this.depth / 2),
            new THREE.Vector3(-this.width / 4, this.height / 8, 0),
            new THREE.Vector3(-this.width / 4, 0, this.zAxisNumber)
          ],
          400,
          200
      )
      this.cloudParameter_second = this.initTubeRoute(
          [
            new THREE.Vector3(this.width / 8, this.height / 8, -this.depth / 2),
            new THREE.Vector3(this.width / 8, this.height / 8, this.zAxisNumber)
          ],
          200,
          100
      )
      this.initRenderer()
      // 控制器必须放在renderer函数后面
      this.initOrbitControls()
      this.animate()
    },

    GUIParams() {
      return {
        color : '#000',
        length : 10,
        size : 3,
        visible : true,
        x : 0,
        y : 0,
        z : 100,
        widthSegments : 64,
        heightSegments : 32,
        radius : 16,
      };
    },
    // 初始化gui
    initGUI () {
      const params = this.GUIParams();
      this.gui.add(params, 'x', -1500, 1500).onChange((x) => {
        //点击颜色面板，e为返回的10进制颜色
        this.Sphere_Group.position.x = x
      })
      this.gui.add(params, 'y', -50, 1500).onChange((y) => {
        //点击颜色面板，e为返回的10进制颜色
        this.Sphere_Group.position.y = y
      })
      this.gui.add(params, 'z', -200, 1000).onChange((z) => {
        //点击颜色面板，e为返回的10进制颜色
        this.Sphere_Group.position.z = z
      })
      this.gui.add(params, 'widthSegments', 0, 64).onChange((widthSegments) => {
        //点击颜色面板，e为返回的10进制颜色
        this.sphereGeometry.parameters.widthSegments = widthSegments
      })
      this.gui.add(params, 'heightSegments', 0, 32).onChange((heightSegments) => {
        //点击颜色面板，e为返回的10进制颜色
        this.sphereGeometry.parameters.heightSegments = heightSegments
      })
      this.gui.add(params, 'radius', 5, 30).onChange((radius) => {
        //点击颜色面板，e为返回的10进制颜色
        this.sphereGeometry.parameters.radius = radius
        this.renderer.render(this.scene, this.camera)
      })
      this.gui.add(params, 'visible').onChange((e) => {
        //这是一个单选框，因为params.visible是一个布尔值，e返回所选布尔值
        // points.visible = e
      })
      this.gui.addColor(params, 'color').onChange((e) => {
        //点击颜色面板，e为返回的10进制颜色
        // pointsMaterial.color.set(e)
      })
    },


    initScene(){
      // 初始化场景
      this.scene = new THREE.Scene()
      // 在场景中添加雾的效果，Fog参数分别代表‘雾的颜色’、‘开始雾化的视线距离’、刚好雾化至看不见的视线距离’
      this.scene.fog = new THREE.Fog(0x000000, 0, 10000)
    },

    initSceneBg () {
      // 初始化背景（盒模型背景，视角在盒子里面，看到的是盒子内部）
      new THREE.TextureLoader().load(require('@/assets/images/sky.png'), (texture) => {
        const geometry = new THREE.BoxGeometry(this.width, this.height, this.depth) // 创建一个球形几何体 SphereGeometry
        const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide }) // 创建基础为网格基础材料
        const mesh = new THREE.Mesh(geometry, material)
        this.scene.add(mesh)
      })
    },


    initOrbitControls(){
      // 初始化轨道控制器
      const controls = new OrbitControls(this.camera, this.renderer.domElement)
      // enabled设置为true是可以使用鼠标控制视角
      controls.enabled = false
      controls.update()
    },

    initCamera  () {
     // 初始化相机
      /**
       * 方式1：固定视野的距离，求满足完整的视野画面需要多大的视域角度
       * tan正切值（直角边除以临边）
       * const mathTan_value = width / 2 / depth
       * 视域角度
       * const fov_angle = (Math.atan(mathTan_value) * 180) / Math.PI
       * 创建透视相机
       * new THREE.PerspectiveCamera(fov_angle, width / height, 1, depth)
       * 场景是一个矩形容器（坐标(0, 0, 0)是矩形容器的中心），相机能看到的距离是depth，
       * camera.position.set(0, 0, depth / 2)
       */

      /**
       * 使用透视相机
       * 参数值分别是：
       * fov（field of view） — 摄像机视锥体垂直视野角度
       * aspect — 摄像机视锥体长宽比
       * near — 摄像机视锥体近端面
       * far — 摄像机视锥体远端面
       * 这里需要注意：透视相机是鱼眼效果，如果视域越大，边缘变形越大。
       * 为了避免边缘变形，可以将fov角度设置小一些，距离拉远一些
       */

      /**
       * 方式2:固定视域角度，求需要多少距离才能满足完整的视野画面
       * 15度等于(Math.PI / 12)
       */
      const fov = 15
      const distance = this.width / 2 / Math.tan(Math.PI / 12)
      this.zAxisNumber = Math.floor(distance - this.depth / 2)
      this.camera = new THREE.PerspectiveCamera(fov, this.width / this.height, 1, 30000)
      /**
       * 这里给z轴的距离加了100，原因是做调整，使得视域更完整
       * 这么做并不代表前面计算错误了，根据前面的计算值并不能很完整的看到
       * 至于原因，我想大概就类似于0.1+0.2不等于0.3吧
       * 所以我自作主张地加了100的值做调整（但是不建议，因为当屏幕足够宽时候会看到边缘）
       */
      // camera.position.set(0, 0, zAxisNumber + 100)
      this.camera.position.set(0, 0, this.zAxisNumber)
      this.camera.lookAt(this.cameraTarget)
      // const helper = new THREE.CameraHelper(camera)
      // helper.update()
      // scene.add(helper)
    },


    initLight () {
      //光源
      const ambientLight = new THREE.AmbientLight(0xffffff, 1)
      // 右下角点光源
      const light_rightBottom = new THREE.PointLight(0x0655fd, 5, 0)
      light_rightBottom.position.set(0, 100, -200)
      this.scene.add(light_rightBottom)
      this.scene.add(ambientLight)
    },


    initSphereModal  () {
      // 初始化球体模型
      //材质
      let material = new THREE.MeshPhongMaterial()
      material.map = new THREE.TextureLoader().load(require('@/assets/images/earth_bg.png'))
      material.blendDstAlpha = 1
      //几何体
      this.sphereGeometry = new THREE.SphereGeometry(50, 64, 32)
      //模型
      this.sphere = new THREE.Mesh(this.sphereGeometry, material)
    },


    initSphereGroup () {
      // 初始化组 --- 球体
      this.Sphere_Group = new THREE.Group()
      this.Sphere_Group.add(this.sphere)
      this.Sphere_Group.position.x = -400
      this.Sphere_Group.position.y = 200
      this.Sphere_Group.position.z = -200
      this.scene.add(this.Sphere_Group)
    },

    // 初始化流动路径
    initTubeRoute (route, geometryWidth, geometryHeigh) {
      const curve = new THREE.CatmullRomCurve3(route, false)
      const tubeGeometry = new THREE.TubeGeometry(curve, 100, 2, 50, false)
      const tubeMaterial = new THREE.MeshBasicMaterial({
        // color: '0x4488ff',
        opacity: 0,
        transparent: true
      })
      const tube = new THREE.Mesh(tubeGeometry, tubeMaterial)
      this.scene.add(tube)

      const clondGeometry = new THREE.PlaneGeometry(geometryWidth, geometryHeigh)
      const textureLoader = new THREE.TextureLoader()
      const cloudTexture = textureLoader.load(require('@/assets/images/cloud.png'))
      const clondMaterial = new THREE.MeshBasicMaterial({
        map: cloudTexture,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        transparent: true
      })
      const cloud = new THREE.Mesh(clondGeometry, clondMaterial)
      this.scene.add(cloud)
      return {
        cloud,
        curve
      }
    },


    initSceneStar(initZposition) {
      // 初始化场景星星效果
      const geometry = new THREE.BufferGeometry()
      const vertices = []
      const pointsGeometry = []
      const textureLoader = new THREE.TextureLoader()
      const sprite1 = textureLoader.load(require('@/assets/images/starflake1.png'))
      const sprite2 = textureLoader.load(require('@/assets/images/starflake2.png'))
      this.parameters = [
        [[0.6, 100, 0.75], sprite1, 50],
        [[0, 0, 1], sprite2, 20]
      ]
      // 初始化500个节点
      for (let i = 0; i < 500; i++) {
        /**
         * const x: number = Math.random() * 2 * width - width
         * 等价
         * THREE.MathUtils.randFloatSpread(width)
         */
        const x = THREE.MathUtils.randFloatSpread(this.width)
        const y = _.random(0, this.height / 2)
        const z = _.random(-this.depth / 2, this.zAxisNumber)
        vertices.push(x, y, z)
      }

      geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))

      // 创建2种不同的材质的节点（500 * 2）
      for (let i = 0; i < this.parameters.length; i++) {
        const color = this.parameters[i][0]
        const sprite = this.parameters[i][1]
        const size = this.parameters[i][2]

        this.materials[i] = new THREE.PointsMaterial({
          size,
          map: sprite,
          blending: THREE.AdditiveBlending,
          depthTest: true,
          transparent: true
        })
        this.materials[i].color.setHSL(color[0], color[1], color[2])
        const particles = new THREE.Points(geometry, this.materials[i])
        particles.rotation.x = Math.random() * 0.2 - 0.15
        particles.rotation.z = Math.random() * 0.2 - 0.15
        particles.rotation.y = Math.random() * 0.2 - 0.15
        particles.position.setZ(initZposition)
        pointsGeometry.push(particles)
        this.scene.add(particles)
      }
      return pointsGeometry
    },



    renderSphereRotate () {
      // 渲染星球的自转
      if (this.sphere) {
        this.Sphere_Group.rotateY(0.001);
      }
    },


    renderStarMove () {
      // 渲染星星的运动
      const time = Date.now() * 0.00005
      this.zprogress += 1
      this.zprogress_second += 1

      if (this.zprogress >= this.zAxisNumber + this.depth / 2) {
        this.zprogress = this.particles_init_position
      } else {
        this.particles_first.forEach((item) => {
          item.position.setZ(this.zprogress)
        })
      }
      if (this.zprogress_second >= this.zAxisNumber + this.depth / 2) {
        this.zprogress_second = this.particles_init_position
      } else {
        this.particles_second.forEach((item) => {
          item.position.setZ(this.zprogress_second)
        })
      }

      for (let i = 0; i < this.materials.length; i++) {
        const color = this.parameters[i][0]

        const h = ((360 * (color[0] + time)) % 360) / 360
        this.materials[i].color.setHSL(color[0], color[1], parseFloat(h.toFixed(2)))
      }
    },


    initCloudMove (
        cloudParameter,
        speed,
        scaleSpeed = 0.0006,
        maxScale = 1,
        startScale = 0
    ) {
      // 初始化云的运动函数
      let cloudProgress = 0
      return () => {
        if (startScale < maxScale) {
          startScale += scaleSpeed
          cloudParameter.cloud.scale.setScalar(startScale)
        }
        if (cloudProgress > 1) {
          cloudProgress = 0
          startScale = 0
        } else {
          cloudProgress += speed
          if (cloudParameter.curve) {
            const point = cloudParameter.curve.getPoint(cloudProgress)
            if (point && point.x) {
              cloudParameter.cloud.position.set(point.x, point.y, point.z)
            }
          }
        }
      }
    },


    initRenderer () {
      //渲染器
      // 开启抗锯齿
      // 在 css 中设置背景色透明显示渐变色
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      // 定义渲染器的尺寸；在这里它会填满整个屏幕
      this.renderer.setSize(this.width, this.height)
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.container.appendChild(this.renderer.domElement)
      this.container.appendChild(this.stats.dom)
      this.renderCloudMove_first = this.initCloudMove(this.cloudParameter_first, 0.0002)
      this.renderCloudMove_second = this.initCloudMove(this.cloudParameter_second, 0.0008, 0.001)
    },


    animate () {
      //动画刷新
      requestAnimationFrame(this.animate)
      this.renderSphereRotate()
      this.renderStarMove()
      this.renderCloudMove_first()
      this.renderCloudMove_second()
      this.renderer.render(this.scene, this.camera)
    }














  }



}

</script>


<style scoped lang="less">
@import "./login";
</style>
