/*
* author: the.one
*
* time: 2022-05-24
*
* desc: 路由权限配置
*
* */

export const routerMeta = (to, from, next) => {
    next();
}
