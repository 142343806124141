import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerMeta } from '@/config/router';
import Login from '@views/login/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '*',
    name: '404',
    redirect: '/login',
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => routerMeta(to, from, next));

export default router
